import React, {useState, useEffect} from "react";
import Axios from "axios";
import {Tabs, Row, Col, Divider} from "antd";
import "antd/dist/antd.css";
import {useTranslation} from "react-i18next";
import "./index.css";
import config from "../../lib/env";
import {convertStringToJsonArray, convertSimpleArray} from "../../utils/mappingUtils";
import {saveAs} from "file-saver";
import {convertDateString, numberWithCommas} from "../../utils/stringHelper";
import {LoadingAnimationWholePage, XaTooltip, errorHandler} from "../../common";
import AnnotationViewer from "./AnnotationViewer";

const Cesium = window.Cesium;
Cesium.Ion.defaultAccessToken = config.ion_defaultAccessToken;

const DeviderCustom = (props) => {
  const {title, className} = props;
  return (
    <>
      <Divider className={className} orientation="left">
        {title}
      </Divider>
    </>
  );
};

const AnnotationDetail = (props) => {
  const {onCancel} = props;
  const [annotationData, setAnnotationData] = useState([]);
  const [originalLocationData, setOriginalLocationData] = useState([]);
  const [wgs84LocationData, setWgs84LocationData] = useState([]);
  const [isViewer, setIsViewer] = useState(false);
  const [epsgString, setEpsgString] = useState(null);
  const [isShowLoad, setIsShowLoad] = useState(false);

  /** i18next */
  const {t} = useTranslation();

  const axios = Axios.create();
  const pointFlags = ["p0", "p1", "p2"]; // 점(Point) 플래그

  // 어노테이션 좌표 탭 정보
  const tabPositionItems = (_tab1, _tab2) => [
    {
      // 원본
      key: "1",
      label: epsgString,
      children: _tab1,
    },
    {
      // WGS84
      key: "2",
      label: "WGS84",
      children: _tab2,
    },
  ];

  const handleLoadAnnotation = async (seq) => {
    const {data} = await axios.get(`/annotations/${seq}`);

    const originalLoc = convertStringToJsonArray(data?.f_location, data?.f_coord_system).original;
    const wgs84Loc = convertStringToJsonArray(data.f_location, data?.f_coord_system).wgs84;

    data.wgs84_location = wgs84Loc;
    data.original_epsg_location = originalLoc;
    data.wgs84_location_xy = convertSimpleArray(wgs84Loc);

    data.is_point =
      pointFlags.includes(data.f_fence_annotation_flag) && data.wgs84_location_xy.length < 3;
    setIsViewer(data.is_point);

    setAnnotationData(data);
    setEpsgString(data.f_epsg);
    setOriginalLocationData(originalLoc);
    setWgs84LocationData(wgs84Loc);
  };

  const downloadFilePipeline = async (id, filePath) => {
    let fileType;
    const url = `/Storage/data_pcd${filePath}`;

    const fileTypeString = filePath.split(".")[1];
    if (fileTypeString.length === 3 && (fileTypeString === "ply" || fileTypeString === "glb")) {
      fileType = fileTypeString;
    } else {
      errorHandler(t("message_error_beginning"), t("message_please_check"));
      return;
    }
    setIsShowLoad(true);

    await Axios.get(url)
      .then((response) => {
        saveAs(new Blob([response.data]), `${id}.${fileType}`);
      })
      .catch((e) => {
        errorHandler(t("message_error_beginning"), e);
      });
    setIsShowLoad(false);
  };

  // 좌표 테이블 설정
  const CoordTableComponent = (column, locCoord) => {
    return (
      <div className="annotation-detail-info-table min-width-td">
        <table>
          <thead>
            <tr>
              {column.map((item) => (
                <th>{item.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {locCoord.length > 0 ? (
              locCoord.map((item) => (
                <tr>
                  <td>{item.index}</td>
                  <td className="specific-align">{item.x}</td>
                  <td className="specific-align">{item.y}</td>
                  <td className="specific-align">{item.z}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4}>{t("table_empty_content")}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const coordTableColumn = [
    {label: "Index", key: "index"},
    {label: "X", key: "x"},
    {label: "Y", key: "y"},
    {label: "Z", key: "z"},
  ];

  const splitFileName = (filePath) => {
    const splitedStringArray = filePath.split("/");
    return splitedStringArray[splitedStringArray.length - 1];
  };

  const customedCutFill = (volume) => {
    return volume ? numberWithCommas(volume, 3) : 0;
  };

  useEffect(() => {
    handleLoadAnnotation(props.annotationMngSq);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.annotationMngSq]);

  return (
    <>
      <>
        <LoadingAnimationWholePage
          isLoading={isShowLoad}
          component={
            <>
              {/* Row, Col 활용하여 정보 표시 (또는 Form 활용) */}
              <Row>
                <Col className="annotation-detail-col1">
                  {/* 지도 매핑 */}
                  <AnnotationViewer data={annotationData} flags={pointFlags} />
                </Col>
                <Col className="annotation-detail-col2">
                  {/* 각종 정보 */}

                  {/* 정보 */}
                  <DeviderCustom title={t("annotation_manage_detail_general")} />
                  <div className="content-line">
                    <div className="annotation-detail-table">
                      <table>
                        <tbody>
                          <tr>
                            <th>{t("annotation_manage_detail_general_project")}</th>
                            <td>
                              ({annotationData.project_mng_sq}) {annotationData.f_proj_nm} /{" "}
                              {annotationData.f_epsg}
                            </td>
                          </tr>
                          <tr>
                            <th>{t("annotation_manage_detail_general_date")}</th>
                            <td>{convertDateString(annotationData.f_create_date)}</td>
                          </tr>
                          <tr>
                            <th>{t("annotation_manage_detail_general_fence")}</th>
                            <td>{annotationData.f_fence_nm}</td>
                          </tr>
                          <tr>
                            <th>{t("annotation_manage_detail_general_type")}</th>
                            <td>
                              {annotationData?.f_param?.type ? annotationData?.f_param?.type : "-"}{" "}
                              / {annotationData?.f_fence_annotation_flag}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {annotationData.f_status === 2 &&
                    (annotationData.f_fence_annotation_flag === "a1" ||
                      annotationData.f_fence_annotation_flag === "a2") && (
                      <>
                        {/* Area일 때 표시할 정보 (Layer) */}
                        <DeviderCustom title={t("annotation_manage_detail_details")} />
                        {annotationData.f_fence_annotation_flag === "a1" && (
                          <div className="annotation-detail-info-area">
                            <table>
                              <tr>
                                <th>{t("annotation_manage_detail_details_area")} (m³)</th>
                                <td style={{paddingLeft: "60px", fontWeight: "500"}}>
                                  {annotationData.f_result
                                    ? numberWithCommas(annotationData.f_result.area)
                                    : "-"}
                                </td>
                              </tr>
                            </table>
                          </div>
                        )}
                        {annotationData.f_fence_annotation_flag === "a2" && (
                          <div className="annotation-detail-info-table min-width-td">
                            <table>
                              <thead>
                                <tr>
                                  <th>{t("annotation_manage_detail_details_table_column_id")}</th>
                                  <th>
                                    {t("annotation_manage_detail_details_table_column_layer")}
                                  </th>
                                  <th>{t("annotation_manage_detail_details_table_column_rock")}</th>
                                  <th>
                                    {t("annotation_manage_detail_details_table_column_cut")} (m³)
                                  </th>
                                  <th>
                                    {t("annotation_manage_detail_details_table_column_fill")} (m³)
                                  </th>
                                  <th>{t("annotation_manage_detail_details_table_column_file")}</th>
                                  <th>
                                    {t("annotation_manage_detail_details_table_column_size")} (KB)
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {annotationData.f_result ? (
                                  annotationData.f_fence_annotation_flag === "a2" &&
                                  annotationData.f_result.fnbgcalc &&
                                  annotationData.f_result.fnbgcalc.map((item) => (
                                    <tr>
                                      <td>{item.id}</td>
                                      <td>{item.f_layer_nm}</td>
                                      <td>{item.rock_sq}</td>
                                      <td className="specific-align cutfill-weight">
                                        {customedCutFill(item.cut)}
                                      </td>
                                      <td className="specific-align cutfill-weight">
                                        {customedCutFill(item.fill)}
                                      </td>
                                      <td
                                        className="click-td"
                                        onClick={() =>
                                          downloadFilePipeline(item.id, item.f_ply_file_name)
                                        }
                                      >
                                        <XaTooltip
                                          content={`Download File (${splitFileName(
                                            item.f_ply_file_name
                                          )})`}
                                          placement="bottomRight"
                                          mainComponent={splitFileName(item.f_ply_file_name)}
                                        />
                                      </td>
                                      <td className="specific-align">
                                        {item.ply_file_size
                                          ? numberWithCommas(item.ply_file_size / 1024, 3)
                                          : 0}
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={7}>{t("table_empty_content")}</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </>
                    )}

                  {/* 좌표 정보 (프로젝트 해당 EPSG) */}
                  <DeviderCustom
                    className="devider-margin-custom"
                    title={t("annotation_manage_detail_position")}
                  />
                  {/* Tab 영역 */}
                  <Tabs
                    defaultActiveKey="1"
                    items={tabPositionItems(
                      CoordTableComponent(coordTableColumn, originalLocationData),
                      CoordTableComponent(coordTableColumn, wgs84LocationData)
                    )}
                  />
                </Col>
              </Row>
            </>
          }
        />
      </>
    </>
  );
};

/* Exports */
export default AnnotationDetail;
