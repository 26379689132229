import Axios from "axios";
import i18next from "i18next";
import {errorHandler, xaMessage} from "../common";
import {languagesSelectOptionList, changeLanguageOnScreen} from "../lib/i18next/i18n";

/**
 * XiteCloud 및 XiteAdmin 서버(백엔드) 상태 확인
 * @returns data 상태 정보 DB 테이블 조회 결과 (JSON)
 */
export const xcHealthCheck = async () => {
  try {
    const _findIndex = (_num) => data.findIndex((item) => item.server_code === _num);
    const {data} = await Axios.get("/xcHealthCheck");

    return {
      admin: data[_findIndex(1)].status,
      sol: data[_findIndex(0)].status,
    };
  } catch (err) {
    errorHandler(i18next.t("message_error_beginning"), err);
    return;
  }
};

/** i18next 언어 목록 */
export const languagesList = languagesSelectOptionList;

/**  i18next 언어 변경 기능 함수 */
export const changeLanguage = (lang) => changeLanguageOnScreen(lang);

export const relocateMain = () => {
  window.location.href = "/";
};

export const relocateNotFoundUrl = () => {
  window.location.href = "/not-found";
};

// 어노테이션 결과 코드 문자열 변환
export const convertAnnotationResultString = (resultCode) => {
  // resultCode -1(3): 실패, 0: ready, 1: 진행중,  2:완료

  return resultCode === 0
    ? i18next.t("annotation_result_code_text_ready")
    : resultCode === 1
    ? i18next.t("annotation_result_code_text_processing")
    : resultCode === 2
    ? i18next.t("annotation_result_code_text_complete")
    : resultCode === 3 || resultCode === -1
    ? i18next.t("annotation_result_code_text_fail")
    : "-";
};

/**
 * 배열 길이 비교
 * @param {*} _array1 좌항 배열
 * @param {*} _array2 우항 배열
 * @param {*} _code 구분 (equal: 동등, left: 좌항, right: 우항, leftEqual: 좌항이 크거나 같음, rightEqual: 우항이 크거나 같음)
 * @returns
 */
export const handleCompareArrayLength = (_array1, _array2, _code) => {
  let _result;
  switch (_code) {
    case "equal":
      _result = _array1.length === _array2.length;
      break;
    case "left":
      _result = _array1.length > _array2.length;
      break;
    case "right":
      _result = _array1.length < _array2.length;
      break;
    case "leftEqual":
      _result = _array1.length >= _array2.length;
      break;
    case "rightEqual":
      _result = _array1.length <= _array2.length;
      break;
    default:
      break;
  }
  return _result;
};
