import React, {useEffect, useState} from "react";
import {Tabs} from "antd";
import Axios from "axios";
import i18next from "i18next";
import {useTranslation} from "react-i18next";
import "./index.css";
import {errorHandler, xaMessage} from "../../common";

import TelematicsConnection from "./TelematicsConnection";
import TelematicsAPI from "./TelematicsAPI";

export const useYnList = [
  {label: i18next.t("general_yes"), value: "Y"},
  {label: i18next.t("general_no"), value: "N"},
];

// 접속 정보 사용 여부 변경
export const handleChangeInfoStatus = async (_category, seqs) => {
  try {
    const {data} = await Axios.post("/tms/status/change", {
      category: _category,
      seq_array: seqs,
    });
    xaMessage("success", i18next.t("message_change_telematics_status_complete"));
  } catch (err) {
    errorHandler(i18next.t("message_error_beginning"), err);
  }
};

/* Main Component */
const Telematics = () => {
  /* 상수 */
  // i18next
  const {t} = useTranslation();

  const [tmsOemList, setTmsOemList] = useState([]);

  const tabItems = [
    {
      // 텔레매틱스 접속 정보
      key: "1",
      label: t("telematics_tab1_connection"),
      children: <TelematicsConnection tmsOemList={tmsOemList} useYnList={useYnList} />,
    },
    {
      // 텔레매틱스 API 정보
      key: "2",
      label: t("telematics_tab2_api"),
      children: <TelematicsAPI tmsOemList={tmsOemList} useYnList={useYnList} />,
    },
  ];

  // 제조사(OEM) 정보 조회
  const fetchOemList = async () => {
    const {data} = await Axios.get("/tms/oemList");
    setTmsOemList(
      data.map((oem) => {
        return {
          label: oem.f_oem_name,
          value: oem.f_oem_code,
        };
      })
    );
  };

  useEffect(() => {
    fetchOemList();
  }, []);

  /* Renderer */
  return (
    <>
      <Tabs defaultActiveKey="1" items={tabItems} />
    </>
  );
};

export default Telematics;
