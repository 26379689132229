import React, {useState, useReducer, useEffect} from "react";
import Axios from "axios";
import {Row, Col, Button, Modal} from "antd";
import "antd/dist/antd.css";
import "./index.css";
import {useTranslation} from "react-i18next";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {convertDateString} from "../../utils/stringHelper";
import SectionDataMngDetail from "./SectionDataMngDetail";
import {SelectProject, SelectCustom, TableRender} from "../../common";
import {convertDatetimeString} from "../../utils/stringHelper";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_PROJECT":
      state = {...state, projectList: action.payload.slice()};
      break;
    default:
  }
  return state;
};

const IconCheck = () => <CheckOutlined className="icon-weight" />;
const IconClose = () => <CloseOutlined className="icon-weight" />;

export const SectionDataMngDispatch = React.createContext(null);

/* Main Component */
const SectionDataMng = (props) => {
  const [gState, setGState] = useReducer(reducer, {
    projectList: [],
  });
  const {projectList} = gState;
  const [sectionDataList, setSectionDataList] = useState([]);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedCommitStatus, setSelectedCommitStatus] = useState(null);
  const [sectionDetailModal, setSectionDetailModal] = useState(false);
  const [selectedSectionInfo, setSelectedSectionInfo] = useState(null);

  /** i18next */
  const {t} = useTranslation();

  const commitStatusList = [
    {label: t("section_data_mng_commit_state_incomplete"), value: 0},
    {label: t("section_data_mng_commit_state_complete"), value: 1},
  ];

  /* user list table */
  const columns = [
    {
      title: t("section_data_mng_table_seq"),
      dataIndex: "seq",
      key: "tblCol01",
    },
    {
      title: t("section_data_mng_table_project_id"),
      dataIndex: "projectId",
      key: "tblCol02",
    },
    {
      title: t("section_data_mng_table_project_name"),
      dataIndex: "projectName",
      key: "tblCol03",
    },
    {
      title: t("section_data_mng_table_commit_date"),
      key: "tblCol04",
      render: (record) => convertDateString(record.commitDate),
    },
    {
      title: t("section_data_mng_table_processing_date"),
      key: "tblCol05",
      render: (record) => convertDatetimeString(record.processingDate),
    },
    {
      title: t("section_data_mng_table_commit_state"),
      key: "tblCol06",
      render: (record) =>
        record.commitStatus === "1" ? commitStatusList[1].label : commitStatusList[0].label,
    },
    {
      title: t("section_data_mng_table_excel"),
      key: "tblCol07",
      render: (_, record) => (record.isExistExcel ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("section_data_mng_table_json"),
      key: "tblCol08",
      render: (_, record) => (record.isExistJson ? <IconCheck /> : <IconClose />),
    },
  ];

  const onChangeProject = (value) => {
    setSelectedProject(value);
  };

  const onChangeCommitStatus = (value) => {
    setSelectedCommitStatus(value);
  };

  // Clear 버튼 클릭
  const onClickClear = () => {
    setSelectedCommitStatus(null);
    setSelectedProject(null);
    setSelectedCommitStatus(null);
  };

  const handleSearch = () => {
    handleGetSectionDataList();
  };

  const handleModalOk = () => {};

  const handleModalCancel = () => {
    setSectionDetailModal(false);
  };

  const handleGetSectionDataList = async () => {
    setIsShowLoading(true);
    {
      const {data} = await Axios.get("/project");
      setGState({type: "SET_PROJECT", payload: data});
    }
    const {data} = await Axios.get("/sectionData/list", {
      params: {
        projectId: selectedProject,
        commitStatus: selectedCommitStatus,
      },
    });
    setSectionDataList(data);
    setIsShowLoading(false);
  };

  const onRowSectionDataList = (record, _) => {
    return {
      // Row 클릭
      onClick: () => {
        setSectionDetailModal(true);
        setSelectedSectionInfo(record);
      },
    };
  };

  useEffect(() => {
    handleGetSectionDataList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Renderer */
  return (
    <SectionDataMngDispatch.Provider>
      {/* 조회 조건 영역 */}
      <Row className="search-area ant-advanced-search-form">
        <Col span={8} key={0} className="search-area-part">
          <SelectProject
            nameSpace={t("general_project")}
            onChange={onChangeProject}
            value={selectedProject}
            selectList={projectList}
          />
        </Col>
        <Col span={8} key={1} className="search-area-part">
          <SelectCustom
            nameSpace={t("section_data_mng_commit_state")}
            onChange={onChangeCommitStatus}
            value={selectedCommitStatus}
            selectList={commitStatusList}
          />
        </Col>
        <Col span={24} key={2} className="search-area-buttons">
          <Button className="button-margin-right" type="primary" onClick={handleSearch}>
            {t("button_search")}
          </Button>
          <Button onClick={onClickClear}>{t("button_clear")}</Button>
        </Col>
      </Row>

      {/* Section Data 목록 영역 */}
      <TableRender
        rowClassName="table-row-action"
        columns={columns}
        dataSource={sectionDataList}
        rowKey={"seq"}
        onRow={onRowSectionDataList}
        isLoading={isShowLoading}
        size="small"
      />

      <Modal
        title={t("section_data_mng_modal_title")}
        open={sectionDetailModal}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        footer={null}
        width={500}
        centered={true}
        maskClosable={false}
        closable={true}
        getContainer={false}
        keyboard={true}
      >
        <SectionDataMngDetail onCancel={handleModalCancel} sectionInfo={selectedSectionInfo} />
      </Modal>
    </SectionDataMngDispatch.Provider>
  );
};

export default SectionDataMng;
