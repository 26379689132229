import React, {useState, useEffect} from "react";
import Axios from "axios";
import {Form, Row, Col, Input, Select, Button} from "antd";
import "antd/dist/antd.css";
import {useTranslation} from "react-i18next";
import {xaMessage, errorHandler} from "../../common";
import "./index.css";

const {Option} = Select;

/* Main Component */
const TelematicsAPIDetails = (props) => {
  // i18next
  const {t} = useTranslation();

  /** State */
  const {onCancel, apiSeq, apiInfo, tmsOemList} = props;
  const [mode, setMode] = useState("new");

  // 임시
  const [inputDisabled, setInputDisabled] = useState(true);

  const [form] = Form.useForm();

  // 취소/닫기
  const onClickCancel = () => {
    form.resetFields();
    setMode("new");
    onCancel();
  };

  // onFinish Failed
  const handleFailed = (err) => {
    errorHandler(t("message_error_beginning"), err, null);
  };

  const handleSave = async (param) => {
    if (!param.fleetSnapShot || !param.singleElements || !param.faults) {
      xaMessage("error", t("message_please_check"));
      return;
    } else {
      let _params = {
        seq: mode === "update" ? param.seq : null,
        f_oem_auth_url: "",
        f_oem_base_url: "",
        f_format_type: "",
        api: {
          FLEETSNAPSHOT: "",
          SINGLEELEMENTS: "",
          FAULTS: "",
        },
      };

      if (mode === "new") {
        await Axios.post("/tms/api/create", _params)
          .then((data) => {
            xaMessage("success", t("message_save_success"));
            onCancel();
          })
          .catch((err) => {
            errorHandler("", err);
          });
      } else if (mode === "update") {
        await Axios.put("/tms/api/update", _params)
          .then((data) => {
            xaMessage("success", t("message_save_success"));
            onCancel();
          })
          .catch((err) => {
            errorHandler("", err);
          });
      }
    }
  };

  // 초기 함수
  const handleInit = (apiInfo) => {
    if (apiInfo) {
      setMode("update");
      form.setFieldsValue({
        seq: apiSeq,
        f_oem_name: apiInfo.f_oem_name,
        f_oem_auth_url: apiInfo.f_oem_auth_url,
        f_oem_base_url: apiInfo.f_oem_base_url,
        f_format_type: apiInfo.f_format_type,
        fleet_snap_shot: apiInfo.api.FLEETSNAPSHOT,
        single_elements: apiInfo.api.SINGLEELEMENTS,
        faults: apiInfo.api.FAULTS,
        f_use_yn: apiInfo.f_use_yn,
      });
    } else {
      setMode("new");
      form.setFieldsValue({
        seq: "",
        f_oem_name: "",
        f_oem_auth_url: "",
        f_oem_base_url: "",
        f_format_type: "",
        fleet_snap_shot: "",
        single_elements: "",
        faults: "",
      });
    }
  };

  useEffect(() => {
    handleInit(apiInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  /* Renderer */
  return (
    <>
      <Form
        form={form}
        layout="horizontal"
        onFinish={handleSave}
        onFinishFailed={handleFailed}
        labelCol={{span: 6}}
        autoComplete="off"
        size="small"
      >
        {mode === "update" && (
          <Row>
            <Col span={12}>
              <Form.Item
                name="seq"
                label={t("telematics_api_info_detail_modal_item_seq")}
                rules={[{required: false}]}
              >
                <Input disabled readOnly={true} />
              </Form.Item>
            </Col>

            {/* 사용 여부 */}
            <Col span={12}>
              <Form.Item
                name="f_use_yn"
                label={t("telematics_api_info_detail_modal_item_use_yn")}
                rules={[{required: false}]}
              >
                <Input disabled readOnly={true} />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row>
          {/* 제조사 */}
          <Col span={12}>
            <Form.Item
              name="f_oem_name"
              label={t("telematics_api_info_detail_modal_item_oem")}
              rules={[{required: false}]}
            >
              <Select disabled={inputDisabled}>
                <Option>{t("selector_select")}</Option>
                {tmsOemList.map((oem) => (
                  <Option key={oem.value} value={oem.value}>
                    {oem.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {/* 유형 */}
          <Col span={12}>
            <Form.Item
              name="f_format_type"
              label={t("telematics_api_info_detail_modal_item_type")}
              rules={[{required: false}]}
            >
              <Input disabled={inputDisabled} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSave}
        onFinishFailed={handleFailed}
        labelCol={{span: 6}}
        autoComplete="off"
        size="small"
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name="fleet_snap_shot"
              label={t("telematics_api_info_detail_modal_item_api_snap")}
              rules={[{required: false}]}
            >
              <Input disabled={inputDisabled} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="single_elements"
              label={t("telematics_api_info_detail_modal_item_api_single")}
              rules={[{required: false}]}
            >
              <Input disabled={inputDisabled} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="faults"
              label={t("telematics_api_info_detail_modal_item_api_faults")}
              rules={[{required: false}]}
            >
              <Input disabled={inputDisabled} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {/* 최하단(Footer 대응) */}
      <Row gutter={16} className="modal-tms-detail-footer-custom">
        <Button className="button-margin" size="middle" onClick={onClickCancel}>
          {t("button_cancel")}
        </Button>
        {/* <Button size="middle" type="primary" htmlType="submit">
          {t("button_save")}
        </Button> */}
      </Row>
    </>
  );
};

/* Exports */
export default TelematicsAPIDetails;
