import React, {useState, useEffect} from "react";
import Axios from "axios";
import {Row, Col, Button, Modal} from "antd";
import "antd/dist/antd.css";
import {useTranslation} from "react-i18next";
import "./index.css";
import {convertDatetimeString} from "../../utils/stringHelper";
import {SelectCustom, TableRender, xaMessage, errorHandler} from "../../common";
import {useYnList, handleChangeInfoStatus} from "./Telematics";
import TelematicsAPIDetails from "./TelematicsAPIDetails";

/* Main Component */
const TelematicsAPI = (props) => {
  /* 상수 */
  // i18next
  const {t} = useTranslation();

  const [isShowLoading, setIsShowLoading] = useState(false);
  const [apiList, setApiList] = useState([]);

  const [selectedOem, setSelectedOem] = useState(null);
  const [selectedUseYn, setSelectedUseYn] = useState(null);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]); // 선택 체크박스의 Key

  const [apiModalVisible, setApiModalVisible] = useState(false);
  const [apiSeq, setApiSeq] = useState(null);
  const [apiInfo, setApiInfo] = useState(null);

  const columns = [
    {
      title: t("telematics_connect_info_table_column_oem"),
      key: "tblTmsConnection0",
      dataIndex: "f_oem_name",
    },
    {
      title: t("telematics_api_info_table_column_auth_url"),
      key: "tblTmsConnection1",
      dataIndex: "f_oem_auth_url",
    },
    {
      title: t("telematics_api_info_table_column_base_url"),
      key: "tblTmsConnection2",
      dataIndex: "f_oem_base_url",
    },
    {
      title: t("telematics_api_info_table_column_data_type"),
      key: "tblTmsConnection3",
      dataIndex: "f_format_type",
    },
    {
      title: t("telematics_connect_info_table_column_create_at"),
      key: "tblTmsConnection5",
      dataIndex: "f_create_at",
      render: (datetime) => convertDatetimeString(datetime),
    },
    {
      title: t("telematics_connect_info_table_column_update_at"),
      key: "tblTmsConnection6",
      dataIndex: "f_update_at",
      render: (datetime) => convertDatetimeString(datetime),
    },
    {
      title: t("telematics_connect_info_table_column_use_yn"),
      key: "tblTmsConnection7",
      dataIndex: "f_use_yn",
    },
  ];

  // 사용여부 변경 버튼
  const onClickUseYn = () => {
    if (selectedRowKeys.length > 0) {
      handleChangeInfoStatus("api", selectedRowKeys, setApiList);
    } else {
      xaMessage("warn", t("message_warn_select_menu"));
      return;
    }
    handleSearch();
  };

  const onClickSearch = () => {
    handleSearch();
  };

  // 장비 제조사 선택
  const onChangeOem = (value) => {
    setSelectedOem(value);
  };

  // 사용여부 선택
  const onChangeUseYn = (value) => {
    setSelectedUseYn(value);
  };

  // 사용자 목록 행(Row) 관련 Action (onRow)
  const onRowApiListRow = (record, _) => {
    return {
      // Row 클릭
      onClick: () => {
        handleOpenApiDetails(record);
      },
    };
  };

  // 초기화 버튼 클릭
  const onClickClear = () => {
    setSelectedRowKeys([]);
    setSelectedOem(null);
    setSelectedUseYn(null);
  };

  // Table Checkbox Key, Data 저장 Controller
  const handleChangeCheckboxes = (key, _) => {
    setSelectedRowKeys(key);
  };

  // Table Checkbox 제어 조건 설정
  const handleSelectRow = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows, _) => {
      if (selectedRowKeys.length === 0) {
        handleChangeCheckboxes([], []);
      } else {
        handleChangeCheckboxes(selectedRowKeys, selectedRows);
      }
    },
  };

  // Modal 열기
  const handleOpenApiDetails = (apiInfo) => {
    setApiModalVisible(true);
    setApiSeq(apiInfo.seq);
    setApiInfo(apiInfo);
  };

  // Modal 닫기
  const handleCancelApiModal = () => {
    setApiModalVisible(false);
    handleSearch();
  };

  // 검색
  const handleSearch = async () => {
    setIsShowLoading(true);
    try {
      const {data} = await Axios.get("/tms/apiList", {
        params: {
          oemName: selectedOem,
          useYn: selectedUseYn,
        },
      });
      setApiList(data);
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
    }
    setIsShowLoading(false);
  };

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Renderer */
  return (
    <>
      {/* 검색 영역 */}
      <div className="search-area common-search-area-form">
        <Row className="search-area-row-1">
          {/* 제조사 */}
          <Col span={8} key={2} className="search-area-column">
            <SelectCustom
              nameSpace={t("telematics_title_oem")}
              onChange={onChangeOem}
              value={selectedOem}
              selectList={props.tmsOemList}
            />
          </Col>

          {/* 사용 여부 */}
          <Col span={8} key={2} className="search-area-column">
            <SelectCustom
              nameSpace={t("telematics_title_use_yn")}
              onChange={onChangeUseYn}
              value={selectedUseYn}
              selectList={useYnList}
            />
          </Col>
        </Row>

        {/* 버튼 영역 */}
        <Row className="search-area-row-2">
          <Col span={8} className="search-area-column">
            <Button disabled={selectedRowKeys.length < 1} onClick={onClickUseYn}>
              {t("telematics_connect_info_button_select_use_yn")}
            </Button>
          </Col>
          <Col>
            <Button type="primary" onClick={onClickSearch}>
              {t("button_search")}
            </Button>
            <Button className="button-margin-left" onClick={onClickClear}>
              {t("button_clear")}
            </Button>
          </Col>
        </Row>
      </div>

      {/* 텔레매틱스 접속 정보 목록 영역 */}
      <TableRender
        rowClassName="table-row-action"
        columns={columns}
        dataSource={apiList}
        rowSelection={handleSelectRow}
        onRow={onRowApiListRow}
        size="small"
        rowKey={"seq"}
        isLoading={isShowLoading}
        pagination={false}
      />

      {/* Menu 상세/신규 Modal */}
      <Modal
        title={
          apiSeq ? t("telematics_api_details_modal_title") : t("telematics_api_details_modal_add")
        }
        open={apiModalVisible}
        onCancel={handleCancelApiModal}
        footer={null}
        width={750}
        getContainer={false}
        maskClosable={false}
      >
        <TelematicsAPIDetails
          onCancel={handleCancelApiModal}
          apiInfo={apiInfo}
          apiSeq={apiSeq}
          tmsOemList={props.tmsOemList}
        />
      </Modal>
    </>
  );
};

export default TelematicsAPI;
