import React, {useState, useEffect} from "react";
import moment from "moment";
import Axios from "axios";
import {Row, Col, Button, Modal, DatePicker} from "antd";
import "antd/dist/antd.css";
import {useTranslation} from "react-i18next";
import "./index.css";
import {convertDatetimeString} from "../../utils/stringHelper";
import {SelectProject, SelectCustom, TableRender, errorHandler, xaMessage} from "../../common";
import {defaultDateFormat, dateWithDashFormat} from "../../utils/stringHelper";
import {useYnList, handleChangeInfoStatus} from "./Telematics";
import TelematicsConnDetails from "./TelematicsConnDetails";

const {RangePicker} = DatePicker;

/* Main Component */
const TelematicsConnection = (props) => {
  /* 상수 */
  // i18next
  const {t} = useTranslation();

  const [isShowLoading, setIsShowLoading] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [assetList, setAssetList] = useState([]);

  const [tmsConnectInfoList, setTmsConnectInfoList] = useState([]);

  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedOem, setSelectedOem] = useState(null);
  const [selectedUseYn, setSelectedUseYn] = useState(null);

  const [startDate, setStartDate] = useState(moment().format(defaultDateFormat)); // 검색 시작 일자
  const [endDate, setEndDate] = useState(moment().add(2, "years").format(defaultDateFormat)); // 검색 종료 일자

  const [selectedRowKeys, setSelectedRowKeys] = useState([]); // 선택 체크박스의 Key

  const [connModalVisible, setConnModalVisible] = useState(false);
  const [connSeq, setConnSeq] = useState(null);
  const [connInfo, setConnInfo] = useState(null);

  const columns = [
    {
      title: t("telematics_connect_info_table_column_oem"),
      key: "tblTmsConnection0",
      dataIndex: "f_oem_name",
    },
    {
      title: t("telematics_connect_info_table_column_model"),
      key: "tblTmsConnection1",
      dataIndex: "f_model_code",
    },
    {
      title: t("telematics_connect_info_table_column_equipment_id"),
      key: "tblTmsConnection2",
      dataIndex: "f_equipment_id",
      render: (equipmentId, record, _) => (record.f_oem_name === "HCE" ? "-" : equipmentId),
    },
    {
      title: t("telematics_connect_info_table_column_serial_no"),
      key: "tblTmsConnection3",
      dataIndex: "f_serial_no",
    },
    {
      title: t("telematics_connect_info_table_column_project_name"),
      key: "tblTmsConnection4",
      dataIndex: "project_mng_sq_t_project_mng",
      render: (projectId) =>
        projectList.find((proj) => proj.project_mng_sq === projectId).f_proj_nm,
    },
    {
      title: t("telematics_connect_info_table_column_fleet_asset_id"),
      key: "tblTmsConnection5",
      width: 120,
      dataIndex: "asset_id_txf_asset_mng",
    },
    {
      title: t("telematics_connect_info_table_column_fleet_asset_name"),
      key: "tblTmsConnection6",
      dataIndex: "asset_id_txf_asset_mng",
      render: (assetId) => assetList.find((asset) => asset.asset_id === assetId).asset_name,
    },
    {
      title: t("telematics_connect_info_table_column_expire_at"),
      key: "tblTmsConnection7",
      width: 180,
      dataIndex: "f_expire_at",
      render: (datetime) => (!datetime ? "-" : convertDatetimeString(datetime)),
    },
    {
      title: t("telematics_connect_info_table_column_create_at"),
      key: "tblTmsConnection8",
      width: 180,
      dataIndex: "f_create_at",
      render: (datetime) => convertDatetimeString(datetime),
    },
    {
      title: t("telematics_connect_info_table_column_update_at"),
      key: "tblTmsConnection9",
      width: 180,
      dataIndex: "f_update_at",
      render: (datetime) => convertDatetimeString(datetime),
    },
    {
      title: t("telematics_connect_info_table_column_use_yn"),
      key: "tblTmsConnection10",
      width: 80,
      dataIndex: "f_use_yn",
    },
  ];

  // 프로젝트 선택
  const onChangeProject = (value) => {
    setSelectedProject(value);
  };

  // 장비 제조사 선택
  const onChangeOem = (value) => {
    setSelectedOem(value);
  };

  // 사용여부 선택
  const onChangeUseYn = (value) => {
    setSelectedUseYn(value);
  };

  // 검색기간 변경
  const onChangeDate = (value) => {
    setStartDate(value[0].format(defaultDateFormat));
    setEndDate(value[1].format(defaultDateFormat));
  };

  // 사용여부 변경 버튼
  const onClickUseYn = () => {
    if (selectedRowKeys.length > 0) {
      handleChangeInfoStatus("conn", selectedRowKeys, setTmsConnectInfoList);
    } else {
      xaMessage("warn", t("message_warn_select_menu"));
      return;
    }
    handleSearch();
  };

  // 추가 버튼
  const onClickAddInfo = () => {
    setConnModalVisible(true);
    setConnSeq(null);
    setConnInfo(null);
  };

  // 검색 버튼
  const onClickSearch = () => {
    handleSearch();
  };

  // 사용자 목록 행(Row) 관련 Action (onRow)
  const onRowConnListRow = (record, _) => {
    return {
      // Row 클릭
      onClick: () => {
        handleOpenConnDetails(record);
      },
    };
  };

  // 초기화 버튼 클릭
  const onClickClear = () => {
    setSelectedRowKeys([]);
    setSelectedProject(null);
    setSelectedOem(null);
    setSelectedUseYn(null);
  };

  // 테이블 체크박스 Key 저장 제어
  const handleChangeCheckboxes = (key, _) => {
    setSelectedRowKeys(key);
  };

  // 테이블 체크박스 제어 조건 설정
  const handleSelectRow = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows, _) => {
      if (selectedRowKeys.length === 0) {
        handleChangeCheckboxes([], []);
      } else {
        handleChangeCheckboxes(selectedRowKeys, selectedRows);
      }
    },
  };

  // Modal 열기
  const handleOpenConnDetails = (connInfo) => {
    setConnModalVisible(true);
    setConnSeq(connInfo.seq);
    setConnInfo(connInfo);
  };

  // Modal 닫기
  const handleCancelConnModal = () => {
    setConnSeq(null);
    setConnInfo(null);
    setConnModalVisible(false);
  };

  // 검색
  const handleSearch = async () => {
    try {
      setIsShowLoading(true);
      {
        const {data} = await Axios.get("/project");
        setProjectList(data);
      }
      {
        const {data} = await Axios.get("/assets");
        setAssetList(data);
      }
      {
        const {data} = await Axios.get("/tms/connectInfoList", {
          params: {
            oemName: selectedOem,
            projectId: selectedProject,
            startAt: startDate,
            endAt: endDate,
            useYn: selectedUseYn,
          },
        });
        setTmsConnectInfoList(
          data
            .filter((item) => (selectedOem ? item.f_oem_name === selectedOem : item))
            .filter((item) =>
              selectedProject ? item.project_mng_sq_t_project_mng === selectedProject : item
            )
            .filter((item) => (selectedUseYn ? item.f_use_yn === selectedUseYn : item))
        );
      }
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
    }
    setIsShowLoading(false);
  };

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Renderer */
  return (
    <>
      {/* 검색 영역 */}
      <div className="search-area common-search-area-form">
        <Row className="search-area-row-1">
          {/* 프로젝트 */}
          <Col span={8} key={0} className="search-area-column">
            <SelectProject
              nameSpace={t("general_project")}
              onChange={onChangeProject}
              value={selectedProject}
              selectList={projectList}
            />
          </Col>

          {/* 제조사 */}
          <Col span={8} key={1} className="search-area-column">
            <SelectCustom
              nameSpace={t("telematics_title_oem")}
              onChange={onChangeOem}
              value={selectedOem}
              selectList={props.tmsOemList}
            />
          </Col>

          {/* 사용 여부 */}
          <Col span={8} key={2} className="search-area-column">
            <SelectCustom
              nameSpace={t("telematics_title_use_yn")}
              onChange={onChangeUseYn}
              value={selectedUseYn}
              selectList={useYnList}
            />
          </Col>
        </Row>

        {/* 만료 일자 */}
        <Row className="search-area-row-1">
          <Col span={8} key={3} className="search-area-column">
            <Row className="search-area-row-1-col">{t("telematics_title_date_range")}: </Row>
            <Row>
              <RangePicker
                className="date-picker"
                format={dateWithDashFormat}
                value={[moment(startDate, defaultDateFormat), moment(endDate, defaultDateFormat)]}
                onChange={onChangeDate}
              />
            </Row>
          </Col>
        </Row>

        {/* 버튼 영역 */}
        <Row className="search-area-row-2">
          <Col span={8} className="search-area-column">
            {/* 사용 여부 전환 버튼 */}
            <Button disabled={selectedRowKeys.length < 1} onClick={onClickUseYn}>
              {t("telematics_connect_info_button_select_use_yn")}
            </Button>
          </Col>
          <Col span={8} className="search-button-area">
            {/* 추가 */}
            <Button type="dashed" onClick={onClickAddInfo}>
              {t("telematics_button_connect_info_add")}
            </Button>
            {/* 검색 */}
            <Button type="primary" onClick={onClickSearch}>
              {t("button_search")}
            </Button>

            {/* 초기화 */}
            <Button className="button-margin-left" onClick={onClickClear}>
              {t("button_clear")}
            </Button>
          </Col>
        </Row>
      </div>

      {/* 텔레매틱스 접속 정보 목록 영역 */}
      <TableRender
        rowClassName="table-row-action"
        columns={columns}
        dataSource={tmsConnectInfoList}
        size="small"
        rowSelection={handleSelectRow}
        onRow={onRowConnListRow}
        rowKey={"seq"}
        isLoading={isShowLoading}
        pagination={false}
      />

      {/* 접속 정보 상세/신규 Modal */}
      <Modal
        title={
          connSeq
            ? t("telematics_connect_info_detail_modal_title")
            : t("telematics_connect_info_detail_modal_add")
        }
        open={connModalVisible}
        // onOk={handleOk}
        onCancel={handleCancelConnModal}
        footer={null}
        width={750}
        getContainer={false}
        maskClosable={false}
      >
        <TelematicsConnDetails
          onCancel={handleCancelConnModal}
          tmsOemList={props.tmsOemList}
          useYnList={useYnList}
          assetList={assetList}
          projectList={projectList}
          connInfo={connInfo}
          connSeq={connSeq}
        />
      </Modal>
    </>
  );
};

export default TelematicsConnection;
