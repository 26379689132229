import React from "react";
import {Select, Row} from "antd";
import "antd/dist/antd.css";
import {useTranslation} from "react-i18next";

const {Option} = Select;
/**
 * 공통 프로젝트 셀렉트박스
 * @param {*} props
 *   - nameSpace 셀렉트박스 명칭
 *   - onChange  변경 시 실행 함수
 *   - value  선택 Value
 *   - selectList 데이터 목록
 * @returns <Select>
 */
export const SelectProject = (props) => {
  /** i18next */
  const {t} = useTranslation();

  const {nameSpace, onChange, value, selectList} = props;
  return (
    <>
      <Row>{nameSpace}:</Row>
      <Row>
        <Select
          className="select-project"
          onChange={onChange}
          value={value}
          showSearch
          optionFilterProp="children"
        >
          <Option>{t("selector_select")}</Option>
          {selectList.map((el) => (
            <Option key={el.project_mng_sq} value={el.project_mng_sq}>
              [{el.project_mng_sq}] {el.f_proj_nm}
            </Option>
          ))}
        </Select>
      </Row>
    </>
  );
};
