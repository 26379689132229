import React, {useState, useEffect} from "react";
import moment from "moment";
import Axios from "axios";
import {Row, Col, Button, DatePicker, Modal} from "antd";
import "antd/dist/antd.css";
import "./index.css";
import {useTranslation} from "react-i18next";
import {orderBy} from "lodash";
import {dateWithDashFormat, removeDashAndSpace, convertDateString} from "../../utils/stringHelper";
import {TableRender} from "../../common/TableRender";
import {SelectProject, xaMessage, errorHandler} from "../../common";
import ParanormalAssetDetail from "./ParanormalAssetDetail";

const {RangePicker} = DatePicker;

/* Main Component */
const ParanormalAssets = (props) => {
  const {t, i18n} = useTranslation();
  const todayMoment = moment().format(dateWithDashFormat);

  /* State */
  const [isShowLoading, setIsShowLoading] = useState(false); // 로딩 애니메이션
  const [isTablePaging, setIsTablePaging] = useState(true); // 표 페이징
  const [assetCodes, setAssetCodes] = useState([]); // 장비 코드 목록
  const [projectList, setProjectList] = useState([]); // 프로젝트 목록
  const [selectedProject, setSelectedProject] = useState(null); // 선택 프로젝트
  const [selectedCheckValues, setSelectedCheckValues] = useState([0]); // (장비)종류 체크박스
  const [paranormalAssetsList, setParanormalAssetsList] = useState([]); // 경로이상 장비 목록
  const [assetInfoDetail, setAssetInfoDetail] = useState([]); // 위치 정보 이상 장비 상세정보
  const [assetRoutesInfo, setAssetRoutesInfo] = useState([]); // 해당 장비 경로 정보
  const [assetInfoModalVisible, setAssetInfoModalVisible] = useState(false); // 위치 정보 이상 장비 상세정보 Modal
  const [assetDetailModalTitle, setAssetDetailModalTitle] = useState(""); // Modal 팝업 제목

  const [startDate, setStartDate] = useState(todayMoment); // 검색 시작 일자
  const [endDate, setEndDate] = useState(todayMoment); // 검색 종료 일자

  const columns = [
    {
      title: t("paranormal_assets_table_column_project_id"),
      dataIndex: "project_mng_sq_t_project_mng",
      key: "tblCol01",
    },
    {
      title: t("paranormal_assets_table_column_project_name"),
      dataIndex: "f_proj_nm",
      key: "tblCol02",
    },
    {
      title: t("paranormal_assets_table_column_equip_type"),
      dataIndex: "c_equip_type",
      key: "tblCol03",
      render: (type) => {
        const _assetTypeInfo = assetCodes.find((asset) => `${asset.code1}${asset.code2}` === type);
        return i18n.language === "ko" ? _assetTypeInfo.description : _assetTypeInfo.code_txt;
      },
    },
    {
      title: t("paranormal_assets_table_column_asset_name"),
      dataIndex: "asset_name",
      key: "tblCol04",
    },
    {
      title: t("paranormal_assets_table_column_company"),
      dataIndex: "f_driver_company",
      key: "tblCol05",
    },
    {
      title: t("paranormal_assets_table_column_driver"),
      dataIndex: "f_driver_name",
      key: "tblCol06",
    },
    {
      title: t("paranormal_assets_table_column_date"),
      dataIndex: "work_date",
      key: "tblCol07",
      render: (_date) => convertDateString(_date),
    },
    {
      title: t("paranormal_assets_table_column_time"),
      dataIndex: "work_time",
      key: "tblCol08",
      render: (_time) => parseInt(_time),
    },
    {
      title: t("paranormal_assets_table_column_count"),
      dataIndex: "count",
      key: "tblCol09",
      render: (_count) => parseInt(_count),
    },
    {
      title: t("paranormal_assets_table_column_min_speed"),
      dataIndex: "min_term_speed",
      key: "tblCol10",
    },
    {
      title: t("paranormal_assets_table_column_max_speed"),
      dataIndex: "max_term_speed",
      key: "tblCol11",
    },
  ];

  // 체크박스 옵션
  const checkboxOptions = [
    {label: t("asset_category_ast_002_dump"), value: 0},
    {label: t("asset_category_etc"), value: 1},
  ];

  // Table 행 CSS
  const rowClassParanormalAssetsList = (record) =>
    record.c_equip_type === "AST002" ? "table-row-action-dump" : "table-row-action-no-dump";

  // Table 행 클릭
  const onRowParanormalAssetsList = (record, _) => {
    // 덤프트럭만 경로를 저장하므로, 이외 유형의 장비는 정보 없음
    if (record.c_equip_type === "AST002") {
      return {
        // Row 클릭
        onClick: () => handleTableRow(record),
      };
    }
  };

  // 프로젝트 선택 시
  const onChangeProject = (value) => {
    setSelectedProject(value);
  };

  // 체크박스 선택 시
  const onChangeCheckbox = (values) => {
    setSelectedCheckValues(values);
  };

  // 검색기간 변경
  const onChangeDate = (value) => {
    setStartDate(value[0].format(dateWithDashFormat));
    setEndDate(value[1].format(dateWithDashFormat));
  };

  // 초기화 버튼 클릭
  const onClickClear = () => {
    setStartDate(todayMoment);
    setEndDate(todayMoment);

    handleSearch();
  };

  // Modal OK 버튼
  const onOkModal = () => {};

  // Modal 닫기
  const onCloseModal = () => {
    setAssetInfoDetail([]);
    setAssetInfoModalVisible(false);
  };

  // Table 행 클릭 시, 호출 함수
  const handleTableRow = async (assetInfo) => {
    setAssetInfoDetail(assetInfo);
    setIsShowLoading(true);

    // 운행 경로 조회
    const _data = await handleGetAssetRoutes(assetInfo);
    setAssetRoutesInfo(_data);

    setIsShowLoading(false);
    setAssetInfoModalVisible(_data !== undefined && _data.length > 0);

    // Modal 제목
    setAssetDetailModalTitle(
      `${t("paranormal_assets_modal_title")} ([${convertDateString(assetInfo.work_date)}] ${
        assetInfo.f_proj_nm
      } / ${assetInfo.asset_name})`
    );
  };

  /**
   * 운행 경로 정보 조회
   * @param {*} assetInfo 해당 장비 상세정보
   * @returns 해당 장비의 경로 정보
   */
  const handleGetAssetRoutes = async (assetInfo) => {
    try {
      const {data} = await Axios.post("/paraormaAssets/assetDetails", {
        params: {
          asset_id: assetInfo.asset_id_txf_asset_mng,
          project_id: assetInfo.project_mng_sq_t_project_mng,
          work_date: assetInfo.work_date,
          work_time: assetInfo.work_time,
        },
      });

      if (
        data.length === 0 ||
        Object.keys(data).length === 0 ||
        (data.length === 1 && !data[0].f_route_loc_txt)
      ) {
        xaMessage("warn", t("paranormal_assets_modal_no_route_data"));
        return;
      }

      return orderBy(data, "f_start_time", "asc");
    } catch (err) {
      errorHandler("", err);
      return;
    }
  };

  /**
   * 선택 프로젝트 Filtering
   * @param {*} _project selectedProject
   * @param {*} _list 경로이상 장비 데이터
   * @returns
   */
  const handleFilteredProject = (_project, _data) =>
    _project ? _data.filter((para) => para.project_mng_sq_t_project_mng === _project) : _data;

  /**
   * 장비 종류 Filtering
   * @param {*} _data 경로이상 장비 데이터
   * @returns
   */
  const handleFilteredAsset = (_data) => {
    const isOnlyDumpSelected = selectedCheckValues.includes(0) && selectedCheckValues.length === 1; // 장비 종류 선택 (덤프트럭 단독)
    // const isOnlyEtcSelected = selectedCheckValues.includes(1) && selectedCheckValues.length === 1; // 장비 종류 선택 (기타 장비 단독)
    const isAllSelected = selectedCheckValues.length === 2;

    // 중복 요소 제거 (해당일 동일 장비의 같은 시각대 정보 있는 경우)
    _data = _data.reduce((acc, current) => {
      // 중복 요소 확인 (project_mng_sq_t_project_mng, asset_id_txf_asset_mng, device_key_txf_device_assign, work_date, work_time가 동일한지 확인)
      const existingIndex = acc.findIndex(
        (item) =>
          item.project_mng_sq_t_project_mng === current.project_mng_sq_t_project_mng &&
          item.asset_id_txf_asset_mng === current.asset_id_txf_asset_mng &&
          item.device_key_txf_device_assign === current.device_key_txf_device_assign &&
          item.work_date === current.work_date &&
          item.work_time === current.work_time
      );

      if (existingIndex !== -1) {
        // 같은 요소 존재 시, count 수치 비교하여 큰 쪽을 남김
        if (acc[existingIndex].count < current.count) {
          acc[existingIndex] = current;
        }
      } else {
        // 미중복 요소는 그대로 추가
        acc.push(current);
      }

      return acc;
    }, []);

    return isAllSelected
      ? _data
      : _data.filter((para) =>
          isOnlyDumpSelected ? para.c_equip_type === "AST002" : para.c_equip_type !== "AST002"
        );
  };

  // 검색
  const handleSearch = async () => {
    setIsShowLoading(true);
    try {
      const {data} = await Axios.get("/paraormaAssets/list", {
        params: {
          start_date: removeDashAndSpace(startDate),
          end_date: removeDashAndSpace(endDate),
        },
      });

      const filterdData = handleFilteredAsset(handleFilteredProject(selectedProject, data));

      setIsTablePaging(filterdData.length > 30);

      setParanormalAssetsList(filterdData);
    } catch (err) {
      errorHandler("", err);
    }
    setIsShowLoading(false);
  };

  // 초기 함수
  const handleInit = async () => {
    setIsShowLoading(true);
    try {
      {
        // 장비 코드
        const {data} = await Axios.get("/code", {params: {code1: "AST"}});
        setAssetCodes(data);
      }
      {
        const {data} = await Axios.get("/project");
        setProjectList(data);
      }
    } catch (err) {
      errorHandler("", err);
    }
    setIsShowLoading(false);
    handleSearch();
  };

  useEffect(() => {
    handleInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* 조회 조건 영역 */}
      <div className="search-area common-search-area-form">
        <Row className="search-area-row-1">
          <Col span={8} className="search-area-column">
            <SelectProject
              nameSpace={t("general_project")}
              onChange={onChangeProject}
              value={selectedProject}
              selectList={projectList}
            />
          </Col>
          <Col span={8} className="search-area-column">
            <Row className="search-area-row-1-col">{t("general_date_range")}: </Row>
            <Row>
              <RangePicker
                format={dateWithDashFormat}
                value={[moment(startDate, dateWithDashFormat), moment(endDate, dateWithDashFormat)]}
                onChange={onChangeDate}
              />
            </Row>
          </Col>
          {/* <Col span={8} className="search-area-column">
            <Row className="search-area-row-1-col col-3-row1">{t("general_category")}</Row>
            <Row>
              <Checkbox.Group
                className="col-3-row2-checkbox"
                options={checkboxOptions}
                defaultValue={selectedCheckValues}
                onChange={onChangeCheckbox}
              />
            </Row>
          </Col> */}
        </Row>
        <Row className="search-area-row-2">
          <Col span={24} className="search-button-area">
            <Button type="primary" onClick={handleSearch}>
              {t("button_search")}
            </Button>
            <Button className="button-margin-left" onClick={onClickClear}>
              {t("button_clear")}
            </Button>
          </Col>
        </Row>
      </div>
      <TableRender
        dataSource={paranormalAssetsList}
        columns={columns}
        rowKey="seq"
        onRow={onRowParanormalAssetsList}
        rowClassName={rowClassParanormalAssetsList}
        size="small"
        bordered={false}
        isLoading={isShowLoading}
        pagination={isTablePaging}
      />

      <Modal
        title={assetDetailModalTitle}
        open={assetInfoModalVisible}
        onOk={onOkModal}
        onCancel={onCloseModal}
        centered={true}
        maskClosable={false}
        closable={true}
        getContainer={false}
        keyboard={true}
        footer={null}
        width={750}
      >
        <ParanormalAssetDetail
          assetInfo={assetInfoDetail}
          routesInfo={assetRoutesInfo}
          onCancel={onCloseModal}
        />
      </Modal>
    </>
  );
};

export default ParanormalAssets;
