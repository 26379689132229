import React, {useState, useEffect} from "react";
import Axios from "axios";
import {Table, Row, Col, Button, Modal, Space} from "antd";
import "antd/dist/antd.css";
import {CheckOutlined, CloseOutlined, MenuOutlined} from "@ant-design/icons";
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";
import {useTranslation} from "react-i18next";
import {arrayMoveImmutable} from "array-move";
import "./index.css";
import {convertDatetimeString} from "../../utils/stringHelper";
import {TableRender, xaMessage, errorHandler} from "../../common";
import MenuDetail from "./MenuDetail";

const IconCheck = () => <CheckOutlined className="icon-weight" />;
const IconClose = () => <CloseOutlined className="icon-weight" />;

/* Main Component */
const MenuMng = () => {
  /* 상수 */
  const [menuList, setMenuList] = useState([]);
  const [adminPermissionList, setAdminPermissionList] = useState([]);
  const [menuMngModalVisible, setMenuMngModalVisible] = useState(false);
  const [adminMenuSq, setAdminMenuSq] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]); // 선택 체크박스의 Key
  const [selectedRowData, setSelectedRowData] = useState([]); // 선택 체크박스의 데이터 Row
  const [selectedMenuSeq, setSelectedMenuSeq] = useState([]); // 선택한 메뉴의 고유번호들
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [isChangeMenuOrder, setIsChangeMenuOrder] = useState(false); // 메뉴 순서 변경 여부
  const [menuOrderList, setMenuOrderList] = useState([]); // 메뉴 순서 저장
  const disabledMenuUrl = ["/MenuMng", "/Users"]; // 관리자 메뉴에서 삭제상태 설정 불가 메뉴

  // i18next
  const {t} = useTranslation();

  /** 테이블 내 드래그 기능 요소 */
  const DragHandle = SortableHandle(() => (
    <>
      <MenuOutlined
        style={{
          cursor: "grab",
          color: "#999",
        }}
      />
    </>
  ));

  const SortableItem = SortableElement((props) => <tr {...props} />);
  const SortableBody = SortableContainer((props) => <tbody {...props} />);

  const onSortEnd = ({oldIndex, newIndex}) => {
    if (oldIndex !== newIndex) {
      const newSortedData = arrayMoveImmutable(menuList.slice(), oldIndex, newIndex).filter(
        (el) => !!el
      );

      setMenuList(newSortedData);
      setIsChangeMenuOrder(true);

      const saveMenuList = newSortedData.map((item, index) => {
        return {
          admin_menu_sq: item.admin_menu_sq,
          new_menu_order: index + 1,
          old_menu_order: item.f_menu_order,
        };
      });
      setMenuOrderList(saveMenuList);
    }
  };

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({className, style, ...restProps}) => {
    const index = menuList.findIndex((x) => x.f_menu_order === restProps["data-row-key"]);
    return <SortableItem index={index} {...restProps} />;
  };

  // Table Checkbox Key, Data 저장 Controller
  const handleChangeCheckboxes = (key, data) => {
    setSelectedRowKeys(key);
    setSelectedRowData(data);
  };

  // Table Checkbox 제어 조건 설정
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows, _) => {
      if (selectedRowKeys.length === 0) {
        handleChangeCheckboxes([], []);
      } else {
        handleChangeCheckboxes(selectedRowKeys, selectedRows);
      }
    },
    getCheckboxProps: (record) => ({
      disabled: disabledMenuUrl.includes(record.f_menu_url),
    }),
  };

  // Table 행 클릭 Action
  const onCellMenuList = (record, index) => {
    return {
      // Row 클릭
      onClick: () => {
        handleMenuDetail(record.admin_menu_sq);
      },
    };
  };

  const columns = [
    {
      title: t("menu_sort"),
      key: "tblMenu00",
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: t("menu_seq"),
      key: "tblMenu01",
      className: "drag-visible",
      dataIndex: "admin_menu_sq",
      render: (text, record, _) => {
        return (
          <>
            <Space size="middle">
              <Button
                type="link"
                size="small"
                onClick={() => handleMenuDetail(record.admin_menu_sq)}
              >
                {text}
              </Button>
            </Space>
          </>
        );
      },
    },
    {
      title: t("menu_name"),
      key: "tblMenu02",
      className: "drag-visible",
      dataIndex: "f_admin_menu_nm",
      render: (text, record, _) => {
        return (
          <>
            <Space size="middle">
              <Button
                type="link"
                size="small"
                onClick={() => handleMenuDetail(record.admin_menu_sq)}
              >
                {text}
              </Button>
            </Space>
          </>
        );
      },
    },
    {
      title: t("menu_url"),
      key: "tblMenu03",
      dataIndex: "f_menu_url",
      className: "drag-visible",
      onCell: onCellMenuList,
    },
    {
      title: t("menu_create"),
      key: "tblMenu04",
      dataIndex: "create_at",
      className: "drag-visible",
      render: (dateTime) => convertDatetimeString(dateTime),
      onCell: onCellMenuList,
    },
    {
      title: t("menu_update"),
      key: "tblMenu05",
      dataIndex: "update_at",
      className: "drag-visible",
      render: (dateTime) => convertDatetimeString(dateTime),
      onCell: onCellMenuList,
    },
    {
      title: t("menu_availability"),
      key: "tblMenu06",
      dataIndex: "f_delete_yn",
      className: "drag-visible",
      render: (isDelete) => (isDelete === "N" ? <IconCheck /> : <IconClose />),
      onCell: onCellMenuList,
    },
  ];

  const handleSearch = () => {
    onClickClear();
    getMenuList();
  };

  const onClickClear = () => {
    setAdminMenuSq(null);
    setSelectedRowKeys([]);
    setSelectedRowData([]);
    setSelectedMenuSeq([]);
    setIsShowLoading(false);
  };

  const onClickChangeStatusMenu = () => {
    if (selectedMenuSeq.length === 0) {
      xaMessage("warn", t("message_warn_select_menu"));
    } else {
      changeMenuDelState();
      xaMessage("success", t("message_change_menu_status_complete"));
      handleSearch();
    }
  };

  const onClickAddMenu = () => {
    setMenuMngModalVisible(true);
    setAdminMenuSq(null);
  };

  const handleMenuDetail = (adminMenuSq) => {
    setMenuMngModalVisible(true);
    setAdminMenuSq(adminMenuSq);
  };

  const handleOk = () => {};

  const handleCancelMenuMng = () => {
    setMenuMngModalVisible(false);
    handleSearch();
  };

  const getMenuList = async () => {
    try {
      const {data} = await Axios.get("/menu");
      setMenuList(data.allMenuInfo);
      setAdminPermissionList(data.adminPermissinInfo);
    } catch (err) {
      errorHandler(t("message_error_beginning"), err);
    }
  };

  const updateMenuOrder = async (menuData) => {
    try {
      await Axios.put("/menu", {
        params: menuData,
      })
        .then(({status}) => {
          if (status === 200) {
            xaMessage("success", t("message_save_success"));
          } else {
            xaMessage("warn", `${t("message_error_occured")}: ${t("message_please_check")}`);
          }
        })
        .catch((err) => {
          xaMessage("warn", err, null, true);
        });
    } catch (err) {
      errorHandler("", err, null);
    }
    setIsChangeMenuOrder(false);
    getMenuList();
  };

  const changeMenuDelState = async () => {
    setIsShowLoading(true);
    try {
      const {data} = await Axios.delete(`/menu`, {
        params: {
          selectedMenuSeq: selectedMenuSeq,
        },
      });
      setMenuList(data);
      setIsShowLoading(false);
    } catch (err) {
      console.log(err);
      setIsShowLoading(false);
    }
  };

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // 선택한 메뉴의 고유번호(admin_menu_sq)들 설정
    setSelectedMenuSeq(
      selectedRowKeys.length > 0 ? selectedRowData.map((i) => i.admin_menu_sq) : []
    );
  }, [selectedRowKeys, selectedRowData]);

  /* Renderer */
  return (
    <>
      {/* 조회 조건 영역 */}
      <Row className="search-area common-search-area-form">
        <Col span={24} key={1} className="menu-search-area-buttons">
          <Row>
            <Button disabled={!selectedMenuSeq.length} onClick={onClickChangeStatusMenu}>
              {t("menu_change_state")}
            </Button>
          </Row>
          <Row>
            <Button disabled={!isChangeMenuOrder} onClick={() => updateMenuOrder(menuOrderList)}>
              {t("menu_save_order")}
            </Button>
            <Button type="dashed" onClick={onClickAddMenu}>
              {t("menu_add")}
            </Button>
            <Button className="button-margin-right" type="primary" onClick={handleSearch}>
              {t("button_search")}
            </Button>
            <Button onClick={onClickClear}>{t("button_clear")}</Button>
          </Row>
        </Col>
      </Row>

      {/* Menu Data 목록 영역 */}
      <TableRender
        columns={columns}
        dataSource={menuList}
        size="small"
        rowSelection={rowSelection}
        rowKey={"f_menu_order"}
        isLoading={isShowLoading}
        pagination={false}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />

      {/* Menu 상세/신규 Modal */}
      <Modal
        title={adminMenuSq ? t("menu_details") : t("menu_add")}
        open={menuMngModalVisible}
        onOk={handleOk}
        onCancel={handleCancelMenuMng}
        footer={null}
        width={800}
        getContainer={false}
        maskClosable={false}
      >
        <MenuDetail
          onCancel={handleCancelMenuMng}
          adminPermissinInfo={adminPermissionList}
          adminMenuSq={adminMenuSq}
        />
      </Modal>
    </>
  );
};

export default MenuMng;
