import React, {useState, useEffect} from "react";
import moment from "moment";
import Axios from "axios";
import {Form, Row, Col, Select, Input, Button, DatePicker} from "antd";
import "antd/dist/antd.css";
import {useTranslation} from "react-i18next";
import {xaMessage, errorHandler} from "../../common";
import {dateWithDashFormat, defaultDateFormat, removeDashAndSpace} from "../../utils/stringHelper";
import "./index.css";

const {Option} = Select;

/* Main Component */
const TelematicsConnDetails = (props) => {
  /** i18next */
  const {t} = useTranslation();

  /** State */
  const {onCancel, connSeq, connInfo, tmsOemList, assetList, projectList} = props;

  const [mode, setMode] = useState("new");
  const [oemCode, setOemCode] = useState(null); // 제조사 코드 State (장비 ID 입력란 활성/비활성 용도)
  const [assetListByProject, setAssetListByProject] = useState([]);

  const [form] = Form.useForm();

  // 제조사 셀렉트박스
  const onChangeOem = (value) => {
    setOemCode(value);
  };

  // 프로젝트 셀렉트박스
  const onChangeProject = (value) => {
    setAssetListByProject(
      assetList.filter((asset) => asset.project_mng_sq_t_project_mng === value)
    );
  };

  // 취소/닫기
  const onClickCancel = () => {
    form.resetFields();
    setMode("new");
    onCancel();
  };

  // onFinish Failed
  const handleFailed = (err) => {
    errorHandler(t("message_error_beginning"), err, null);
  };

  // Submit (저장)
  const handleSave = async (params) => {
    if (
      !params.f_model_code ||
      !params.f_serial_no ||
      !params.f_client_id ||
      !params.f_client_key
    ) {
      xaMessage("error", t("message_please_check"));
      return;
    } else {
      let _params = {
        seq: mode === "update" ? params.seq : null,
        oemName: params.f_oem_name,
        modelCode: params.f_model_code,
        equipmentId:
          params.f_equipment_id === "" || params.f_equipment_id === " " || !params.f_equipment_id
            ? null
            : params.f_equipment_id,
        serialNo: params.f_serial_no,
        clientId: params.f_client_id,
        clientKey: params.f_client_key,
        projectId: params.project_mng_sq_t_project_mng,
        assetId: params.asset_id_txf_asset_mng,
        expireAt:
          !params.f_expire_at || params.f_expire_at === ""
            ? null
            : typeof params.f_expire_at === "string"
            ? removeDashAndSpace(String(params.f_expire_at).split("T")[0])
            : typeof params.f_expire_at === "object"
            ? params.f_expire_at.format(defaultDateFormat)
            : null,
        useYn: mode === "update" ? params.f_use_yn : null,
      };

      if (mode === "new") {
        await Axios.post("/tms/connect/create", _params)
          .then((data) => {
            xaMessage("success", t("message_save_success"));
            onClickCancel();
          })
          .catch((err) => {
            errorHandler("", err);
          });
      } else if (mode === "update") {
        await Axios.post("/tms/connect/update", _params)
          .then((data) => {
            xaMessage("success", t("message_save_success"));
            onClickCancel();
          })
          .catch((err) => {
            errorHandler("", err);
          });
      }
    }
  };

  // 초기 함수
  const handleInit = (connInfo) => {
    if (connInfo) {
      setMode("update");
      form.setFieldsValue(connInfo);
      setOemCode(connInfo.f_oem_name);
      setAssetListByProject(
        assetList.filter(
          (asset) => asset.project_mng_sq_t_project_mng === connInfo.project_mng_sq_t_project_mng
        )
      );
    } else {
      setMode("new");
      form.setFieldsValue({
        seq: "",
        f_client_id: "",
        f_client_key: "",
        f_create_at: "",
        f_equipment_id: "",
        f_expire_at: "",
        f_model_code: "",
        f_oem_name: "",
        f_serial_no: "",
        f_update_at: "",
        f_use_yn: "",
        project_mng_sq_t_project_mng: "",
        asset_id_txf_asset_mng: "",
      });
    }
  };

  useEffect(() => {
    handleInit(connInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  /* Renderer */
  return (
    <>
      <Form
        form={form}
        layout="horizontal"
        onFinish={handleSave}
        onFinishFailed={handleFailed}
        labelCol={{span: 7}}
        autoComplete="off"
        size="small"
      >
        {mode === "update" && (
          <Row>
            {/* 일련 번호 */}
            <Col span={12}>
              <Form.Item
                name="seq"
                label={t("telematics_connect_info_detail_modal_item_seq")}
                rules={[{required: false}]}
              >
                <Input disabled readOnly={true} />
              </Form.Item>
            </Col>

            {/* 사용 여부 */}
            <Col span={12}>
              <Form.Item
                name="f_use_yn"
                label={t("telematics_connect_info_detail_modal_item_use_yn")}
                rules={[{required: false}]}
              >
                <Input disabled readOnly={true} />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row>
          {/* 제조사 */}
          <Col span={12}>
            <Form.Item
              name="f_oem_name"
              label={t("telematics_connect_info_detail_modal_item_oem")}
              rules={[{required: false}]}
            >
              <Select onChange={onChangeOem}>
                <Option>{t("selector_select")}</Option>
                {tmsOemList.map((oem) => (
                  <Option key={oem.value} value={oem.value}>
                    {oem.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {/* 모델 코드 */}
          <Col span={12}>
            <Form.Item
              name="f_model_code"
              label={t("telematics_connect_info_detail_modal_item_model_code")}
              rules={[{required: false}]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          {/* 시리얼 번호 */}
          <Col span={12}>
            <Form.Item
              name="f_serial_no"
              label={t("telematics_connect_info_detail_modal_item_serial_no")}
              rules={[{required: false}]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            {/* 장비 ID */}
            <Form.Item
              name="f_equipment_id"
              label={t("telematics_connect_info_detail_modal_item_equip_id")}
              rules={[{required: false}]}
            >
              <Input disabled={oemCode === "HCE"} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            {/* Cliend ID */}
            <Form.Item
              name="f_client_id"
              label={t("telematics_connect_info_detail_modal_item_client_id")}
              rules={[{required: false}]}
            >
              <Input />
            </Form.Item>
          </Col>

          {/* Client Key (Secret Key) */}
          <Col span={12}>
            <Form.Item
              name="f_client_key"
              label={t("telematics_connect_info_detail_modal_item_client_key")}
              rules={[{required: false}]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          {/* 만료 일시 */}
          <Col span={12}>
            <Form.Item
              name="f_expire_at"
              label={t("telematics_connect_info_detail_modal_item_expire_at")}
              getValueProps={(value) => {
                return {value: value ? moment(value) : null};
              }}
              rules={[{required: false}]}
            >
              <DatePicker className="date-picker-style" format={dateWithDashFormat} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          {/* 프로젝트 */}
          <Col span={12}>
            <Form.Item
              name="project_mng_sq_t_project_mng"
              label={t("telematics_connect_info_detail_modal_item_project")}
              rules={[{required: false}]}
            >
              <Select showSearch optionFilterProp="children" onChange={onChangeProject}>
                <Option>{t("selector_select")}</Option>
                {projectList.map((project) => (
                  <Option key={project.project_mng_sq} value={project.project_mng_sq}>
                    [{project.project_mng_sq}] {project.f_proj_nm}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {/* XiteCloud Fleet 장비 정보 */}
          <Col span={12}>
            <Form.Item
              name="asset_id_txf_asset_mng"
              label={t("telematics_connect_info_detail_modal_item_asset_name")}
              rules={[{required: false}]}
            >
              <Select showSearch optionFilterProp="children">
                <Option>{t("selector_select")}</Option>
                {assetListByProject.map((asset) => (
                  <Option key={asset.asset_id} value={asset.asset_id}>
                    [{asset.asset_id}] {asset.asset_name} ({asset.f_driver_name})
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* 최하단(Footer 대응) */}
        <Row gutter={16} className="modal-tms-detail-footer-custom">
          <Button className="button-margin" size="middle" onClick={onClickCancel}>
            {t("button_cancel")}
          </Button>
          <Button size="middle" type="primary" htmlType="submit">
            {t("button_save")}
          </Button>
        </Row>
      </Form>
    </>
  );
};

/* Exports */
export default TelematicsConnDetails;
